<template>
  <div>
    <Navbar page="Plano de ação integral (PAI)" link="/entregaveis" nameLink="Entregáveis" video="https://youtu.be/kPF2KFRAkak" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-5 rounded-lg px-1 py-2">
          <h2 class=" mb-3 font-semibold">Avanço no plano de ação</h2>
            <apexchart 
              height="240" 
              type="donut" 
              :options="optionsGeral" 
              :series="seriesGeral"/>
        </div>
        
        <div class="col-span-12 md:col-span-7 flex flex-col gap-2 justify-around">
          
          <div>
           <span class="bg-gray-100 py-2 px-2 rounded-full">
              <b>Total de itens no plano de ação:</b> {{ total }}
           </span>
          </div>

          <div class="flex gap-1">
            <div>
              <svg 
                class="h-10 w-10 fill-gray-500"
                version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M2504 5019 c-17 -19 -19 -41 -22 -183 -4 -184 6 -230 52 -242 45 -11
                76 4 92 43 12 29 15 70 12 199 -3 186 -10 204 -78 204 -25 0 -43 -7 -56 -21z"/>
                <path d="M1480 4758 c-28 -31 -25 -61 16 -142 77 -150 145 -257 172 -267 60
                -23 115 38 93 103 -16 44 -148 277 -174 306 -27 29 -79 30 -107 0z"/>
                <path d="M3533 4758 c-27 -29 -160 -263 -174 -306 -22 -66 33 -126 93 -103 39
                15 208 309 208 363 0 61 -85 91 -127 46z"/>
                <path d="M2388 4359 c-93 -13 -120 -23 -142 -52 -24 -33 -15 -70 24 -98 24
                -17 30 -17 125 -3 122 19 332 14 450 -11 257 -54 484 -177 669 -361 175 -175
                285 -368 354 -624 24 -88 26 -111 26 -305 0 -204 -1 -214 -32 -328 -36 -137
                -115 -309 -194 -424 -29 -43 -111 -136 -181 -208 -177 -179 -251 -305 -275
                -466 l-7 -54 -183 -3 -182 -2 0 345 c0 332 -1 346 -20 365 -30 30 -83 27 -109
                -6 -20 -26 -21 -38 -21 -365 l0 -339 -105 0 -105 0 0 585 0 585 104 0 105 0 3
                -146 c3 -139 4 -147 27 -165 30 -25 62 -24 95 2 26 20 26 21 26 164 l0 143 99
                4 c88 3 104 7 147 34 134 82 172 251 84 373 -124 171 -390 131 -458 -68 -7
                -19 -12 -69 -12 -112 l0 -79 -109 0 -108 0 -6 89 c-11 179 -102 274 -262 275
                -151 1 -255 -104 -255 -257 0 -112 66 -205 170 -242 24 -8 77 -15 121 -15 l79
                0 0 -585 0 -585 -178 0 -179 0 -17 83 c-33 153 -119 302 -243 417 -84 79 -168
                175 -219 251 -355 534 -279 1235 182 1680 106 102 188 161 309 223 50 26 96
                54 103 63 32 44 -3 113 -59 113 -34 0 -142 -52 -244 -118 -328 -211 -563 -561
                -642 -959 -22 -111 -24 -402 -4 -513 60 -328 212 -604 474 -856 133 -129 193
                -248 206 -411 8 -95 25 -123 78 -123 l33 0 0 -90 0 -90 -59 0 c-52 0 -62 -3
                -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0 -80 0 -80 -59 0
                c-52 0 -62 -3 -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0
                -32 c0 -45 40 -126 81 -167 39 -38 102 -70 139 -71 34 0 41 -7 69 -62 34 -67
                113 -143 190 -181 62 -30 72 -32 176 -32 103 0 114 2 177 32 80 38 152 106
                194 183 27 50 33 55 73 62 112 19 211 128 211 233 l0 35 54 0 c68 0 106 26
                106 74 0 54 -20 70 -92 76 l-63 5 -3 78 -3 77 54 0 c69 0 107 26 107 74 0 54
                -20 70 -92 76 l-63 5 0 85 0 85 38 6 c48 8 66 32 67 89 1 75 28 181 66 259 30
                61 62 101 178 221 241 248 362 463 428 755 25 115 36 388 19 507 -67 468 -355
                878 -772 1098 -161 84 -321 136 -492 159 -90 13 -305 13 -389 0z m-124 -1411
                c41 -18 59 -56 64 -135 l5 -73 -78 0 c-74 0 -78 1 -111 34 -27 27 -34 42 -34
                74 0 82 80 134 154 100z m752 -7 c42 -26 60 -78 44 -127 -18 -52 -59 -74 -142
                -74 l-68 0 0 74 c0 60 4 78 21 100 38 49 93 58 145 27z m74 -1761 l0 -90 -505
                0 -505 0 0 90 0 90 505 0 505 0 0 -90z m0 -320 l0 -80 -505 0 -505 0 0 80 0
                80 505 0 505 0 0 -80z m0 -251 c0 -27 -27 -72 -54 -90 -17 -11 -95 -15 -411
                -17 -214 -2 -409 0 -433 3 -53 8 -100 48 -108 93 l-6 32 506 0 506 0 0 -21z
                m-295 -283 c-18 -27 -77 -70 -118 -85 -87 -33 -218 0 -286 73 l-35 36 227 0
                227 0 -15 -24z"/>
                <path d="M755 3975 c-32 -31 -33 -75 -2 -102 45 -41 288 -173 318 -173 59 0
                91 73 52 118 -26 29 -295 182 -322 182 -12 0 -33 -11 -46 -25z"/>
                <path d="M4150 3915 c-79 -46 -150 -92 -157 -101 -19 -26 -16 -71 7 -94 35
                -35 71 -25 212 56 157 90 178 107 178 146 0 35 -40 78 -72 78 -13 0 -88 -38
                -168 -85z"/>
                <path d="M490 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0 187
                0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205 -20z"/>
                <path d="M4220 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0
                187 0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205
                -20z"/>
                <path d="M934 2028 c-78 -46 -149 -89 -158 -97 -22 -19 -21 -83 2 -104 35 -32
                75 -20 214 59 142 82 178 111 178 148 0 33 -29 65 -65 71 -25 5 -55 -9 -171
                -77z"/>
                <path d="M3990 2097 c-13 -7 -29 -25 -35 -40 -17 -49 9 -75 160 -164 150 -87
                196 -100 228 -65 22 25 22 85 0 104 -29 25 -298 178 -314 178 -8 0 -25 -6 -39
                -13z"/>
                </g>
                </svg>
            </div>
            <div class="text-gray-500 flex flex-col gap-1 text-sm text-justify">
              <span class="font-bold">
                Orientação rápida
              </span>
              <span>
                Nesta tela, é possível visualizar todos os itens que compõem o Plano de Ação Integral. Esses itens são resultado das respostas classificadas
                como "não" ou "parcialmente" em um questionário diagnóstico, bem como dos registros de vulnerabilidades identificadas durante o mapeamento de dados pessoais ou nas análises de risco.  
              </span>
              <span class="mt-2">
                Para atualizar este plano de ação, basta clicar na opção "atualizar" (último item da coluna). Além disso, é possível extrair o relatório em formato XLS ao clicar no botão "Baixar Plano de Ação em XLS".
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
          <a 
            :href="`${url_api}/v1/diagnosticoAplicado/exportar/relatorioExcel?token=${$store.state.token}&&empresa=${this.$store.state.empresa._id}&&nome=${this.$store.state.empresa.nome || ''}`"
            rel="noreferrer" 
            class="w-full md:w-auto lg:w-auto bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 text-white flex gap-2 py-2 items-end px-8 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center"
          >
              <svg  class=" h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256">
                <path d="M208,28H72A28,28,0,0,0,44,56V224a4,4,0,0,0,4,4H192a4,4,0,0,0,0-8H52v-4a20,20,0,0,1,20-20H208a4,4,0,0,0,4-4V32A4,4,0,0,0,208,28Zm-4,160H72a27.94,27.94,0,0,0-20,8.42V56A20,20,0,0,1,72,36H204Z">
                </path>
              </svg>
              Baixar Plano de Ação em XLS
          </a>
      </div>
      <div class="flex flex-wrap gap-3 my-3">

        <section >
            <input 
              v-model="filtro.executor" 
              type="text" 
              name="executor" id="executor" 
              placeholder="Filtro por executor" 
              class="focus:ring-yellow-400 h-10 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
          >
        </section>

        <section>
          <select 
            v-model="filtro.complexidade" 
            name="filtrocomplexidade" 
            id="filtrocomplexidade" 
            class="w-full py-2 px-8 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
          >
              <option default :value="null">Filtro por complexidade</option>  
              <option 
                v-for="(opcao, idx) in opcoesComplexidade" 
                :value="opcao" 
                :key="idx"
              >
                {{ opcao }}
              </option>
          </select>
        </section>

        <section>
          <select 
            v-model="filtro.grauDeRisco" 
            name="filtrograuDeRisco" 
            id="filtrograuDeRisco" 
            class="w-full py-2 px-8 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
          >
              <option default :value="null">Filtro por grau de risco</option>  
              <option v-for="(grau, idx) in grausDeRiscos" :value="grau" :key="idx">{{ grau }}</option>
          </select>
        </section>

        <section>
          <select 
            v-model="filtro.status" 
            name="filtroStatus" 
            id="filtroStatus" 
            class="w-full py-2 px-8 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
          >
              <option default :value="null">Filtro por status</option>  
              <option v-for="(status, idx) in statusPossiveis" :value="status" :key="idx">{{ status }}</option>
          </select>
        </section>

        <section >
          <button @click="updateFiltro" class="bg-gray-500 py-2 px-8 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </section>

      </div>
      <div class="flex flex-col gap-2">
            <div class="shadow border-b border-gray-200 overflow-x-auto">
              <table class="divide-y divide-gray-200 border-spacing-4">
                <thead class="bg-gray-50 font-bold text-xs tracking-wider">
                  <tr>
                    <th class="text-left p-1">
                      Ref
                    </th>
                    <th class="text-left p-1">
                      Origem
                    </th>
                    <th>
                      Setor / responsável
                    </th>
                    <th>
                      Tema / Atividade
                    </th>
                    <th>
                      Pergunta
                    </th>
                    <th>
                      Resposta
                    </th>
                    <th>
                      Item / Problema / Vulnerabilidade
                    </th>
                    <th>
                      Conduta / Controle
                    </th>
                    <th>
                      Complexidade
                    </th>
                    <th>
                      Grau de risco
                    </th>
                    <th>
                      Aprovada por
                    </th>
                    <th>
                      Executor
                    </th>
                    <th>
                      Prazo inicial
                    </th>
                    <th>
                      Prazo Final
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(item, index) in list" :key="index" 
                    class="text-xs text-center odd:bg-white even:bg-slate-100 text-gray-500"
                  >
                    <td class="text-left p-1">
                      {{ (item.pergunta.pai && item.pergunta.pai.ref) ? item.pergunta.pai.ref : '-' }}
                    </td>
                    <td class="whitespace-nowrap  p-1">
                        {{ item.atividade ? 'PAI-M2': 'PAI-M1' }}
                    </td>
                    <td class="px-2" v-if="!item.atividade">
                        <span v-if="item.diagAplicado && item.diagAplicado.destinatarioAgente">
                          Agente: <br><b>{{ item.diagAplicado.destinatarioAgente.nome }}</b>
                        </span>
                        <span v-if="item.diagAplicado && item.diagAplicado.destinatarioPessoa">
                          Pessoa: <br><b><span>{{ item.diagAplicado.destinatarioPessoa.setor ? item.diagAplicado.destinatarioPessoa.setor.nome : "Sem setor" }}</span> / {{ item.diagAplicado.destinatarioPessoa.nome }}</b>
                        </span>
                    </td>
                    <td class="py-2 font-bold" v-else>
                        <span>{{ item.setor ? item.setor.nome : "Sem setor" }}
                        </span> {{ item.responsavel ? `/ ${item.responsavel.nome}`: '' }}
                    </td>
                    <td>
                        {{ item.tema }}
                    </td>
                    <td>
                        {{ item.pergunta ? item.pergunta.pergunta : '' }}
                    </td>
                    <td>
                        {{ item.pergunta ? item.pergunta.resposta : ''}}
                    </td>
                    <td>
                      {{ 
                        formatarListas(item.pergunta.vulnerabilidades.map(v => v.nome))
                      }}
                    </td>     

                    <td class="w-72">
                      {{ 
                        formatarListas(item.pergunta.respostaNao.map(v => v.nome)) 
                      }}
                    </td>

                    <td>
                      {{ item.pergunta.pai.complexidade ? item.pergunta.pai.complexidade : '' }}
                    </td>

                    <td class="w-16">
                        <span :style="`color: ${statusGrauDeRisco(item.pergunta.pai.grauDerisco)}`">
                          {{ item.pergunta.pai.grauDerisco }}
                        </span>
                    </td>

                    <td class="w-32">
                        <span v-if="item.pergunta.pai.condutaAprovada && item.pergunta.pai.quemAprovou">{{ item.pergunta.pai.quemAprovou }}</span>
                    </td>

                    <td class="divide-y divide-gray-200">
                      {{ formatarListas(item.pergunta.pai.responsaveisPelaConduta.map((i)=>i.nome)) }}
                    </td>

                    <td>
                        <span v-if="item.pergunta.pai.prazoDeExecucao.de">
                          {{ item.pergunta.pai.prazoDeExecucao.de | moment("DD/MM/YYYY") }}
                        </span>
                    </td>
                    <td>
                        <span v-if="item.pergunta.pai.prazoDeExecucao.ate">
                          {{ item.pergunta.pai.prazoDeExecucao.ate | moment("DD/MM/YYYY") }}
                        </span>
                    </td>

                    <td class="font-bold" :style="`color: ${getColorStatus(item.pergunta.pai.status)}`">
                        {{ item.pergunta.pai.status }}
                        <span v-if="item.pergunta.pai.concluidoEm">
                          em {{ item.pergunta.pai.concluidoEm  | moment("DD/MM/YYYY")}}
                        </span>
                    </td>           
                    <td class="flex-col items-center p-2">
                      <div class="flex flex-col gap-1">
                        <router-link 
                          v-if="item.controle && item.pergunta._id"
                          :to="`/diagnosticos/visualizar/${item.controle}/revisar/${item._id}/${item.pergunta._id}?origem=revisar`" 
                          type="button" 
                          class="text-center rounded-md bg-green-600 hover:bg-green-700 text-white px-2 py-1"
                        >
                            Atualizar
                        </router-link>
                        <router-link 
                          v-else
                          :to="`/mapeamento/form/${item._id}`" 
                          type="button" 
                          class="text-center rounded-md bg-green-600 hover:bg-green-700 text-white px-2 py-1"
                        >
                            Atualizar
                        </router-link>
                        <button
                          @click="abrirModalEvidencias(item.pergunta.pai)" 
                          type="button" 
                          class="text-center rounded-md bg-purple-600 hover:bg-purple-700 text-white px-2 py-1"
                        >
                            Evidências
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <label for="perPage">Itens por página

              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>

            </label>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
      </div>

      <modal name="modalEvidencias" :width="800" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="px-3 py-3">
          <h2 class="text-2xl font-bold mb-3">Evidências</h2>
          <table class="min-w-full divide-y divide-gray-200" v-if="visualizarEvidencias && visualizarEvidencias.length>0">
            <thead class="bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Arquivo
                    </th>
                    <th  scope="col" class="px-6 py-3">
                        Opção
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 text-sm text-gray-500">
                <tr 
                    v-for="(item, indexEvidencia) in visualizarEvidencias" 
                    :key="indexEvidencia"
                >
                    <td class="px-6 py-4 ">
                        {{item.originalname ? item.originalname : ''}}
                    </td>
                    <td class="px-6 py-4 flex gap-2 flex-wrap items-center">
                        <a 
                            class="text-blue-500"
                            target="_blank" 
                            :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">
                            Visualizar
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button 
                  @click="$modal.hide('modalEvidencias')" 
                  type="button" 
                  class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      list: [],
      page: 1,
      perPage: 20,
      desativadas: false,
      total: 0,
      filtro: {
        tipoDiagnostico: null,
        status: null,
        grauDeRisco: null,
        complexidade: null,
        executor: null,
      },
      visualizarEvidencias: null,
      grausDeRiscos: ['Muito baixo', 'Baixo', 'Médio', 'Alto', 'Muito Alto'],
      opcoesComplexidade: ['Baixo', 'Médio', 'Alto'],
      statusPossiveis: ['Pendente', 'Em andamento', 'Concluído', 'Atrasada'],//Pendente, Em andamento, Concluído
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Pendente", "Em andamento", "Concluído", "Atrasada"],
        colors: ["#a0a0a0", "#ffd700", "#00E396", "#FF4560"]
      },
      seriesGeral: [0, 0],
      query: { page: 1, limit: 10, skip: 0, desativadas: false },
    }
  },
  methods: {
    formatarListas(lista){
      try{
        const listaFiltrada = lista.filter(item => item).map(item => String(item));

        return new Intl.ListFormat('pt-BR', { style: 'long', type: 'conjunction' })
        .format(listaFiltrada);
      } catch(e){
        console.log('Error', e)
        return '';
      }
    },
    getColorStatus(status) {
      switch (status) {
        case 'Pendente':
          return "#a0a0a0";
        case 'Em andamento':
          return "#ffd700";
        case 'Concluído':
          return "#00E396";
        case 'Atrasada':
          return "#FF4560";
      }
    },
    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        filtro: JSON.stringify(this.filtro),
        t: moment().format('x'),
      };

      this.$router.push({ path: `/entregaveis/pai`, query: this.query });
    },
    statusGrauDeRisco(status){
      if(status === 'Muito baixo') return '#3a9669';
      if(status === 'Baixo') return '#1b4e3b';
      if(status === 'Médio') return '#f29e0c';
      if(status === 'Alto') return '#aa1a1a';
      if(status === 'Muito Alto') return '#ff2626';
    },
    abrirModalEvidencias(pai){
      if(pai.evidencias && pai.evidencias.length>0){
        this.visualizarEvidencias = pai.evidencias;
        this.$modal.show('modalEvidencias');
      }else return this.$vToastify.warning("O plano de ação não possui evidências");
    },
    async start() {

      this.query.limit = this.perPage;

      await this.$http.post(`/v1/diagnosticoAplicado/perguntas/vinculo-pai`, {
        filtro: this.filtro,
        skip: this.query.skip, 
        limit: this.query.limit,
      })
      .then((resp)=>  {
        this.seriesGeral = [
          resp.data.resumo.pendente, 
          resp.data.resumo.emAndamento,
          resp.data.resumo.concluido,
          resp.data.resumo.atrasada,
        ];

        this.list = resp.data.data
        this.total = resp.data.total
      })
    },

    async setPage(page) {
      console.log("SET PAGE");
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/entregaveis/pai`, query: this.query });
    },

  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>